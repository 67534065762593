import "@fontsource-variable/manrope";
import "./style.css";

import { createApp, h, Fragment } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import { register as registerSwiperElement } from "swiper/element/bundle";
import setupKeycloak from "./helpers/setupKeycloak";
import { useFeatureStore } from "@/stores/feature";
import shadow from "vue-shadow-dom";
import PrimeVue from "primevue/config";
import { useAuthStore } from "@/stores/auth";
import IAMService from "@/services/iam";
import directives from "./directives";
import activateInactivityLogout from "./services/inactivity";
import analytics, { AnalyticsPluginOptions } from "./an4lytics/plugin";

setupKeycloak()
  .then(async (keycloak) => {
    // setup app instance
    const app =
      process.env.NODE_ENV === "development"
        ? await createAppWithVueAxe()
        : createApp(App);

    // setup pinia
    const pinia = createPinia();
    app.use(pinia);
    // initialize FeatureStore
    useFeatureStore()
      .initializeFlags()
      .then(() => {
        // inactivity logout
        const use_feature = useFeatureStore().flags.automatic_logout;
        if (use_feature) {
          activateInactivityLogout();
        }
      });
    // initialize AuthStore
    const authStore = useAuthStore();
    if (keycloak.authenticated) {
      authStore.login(keycloak);
      IAMService.check_login_data();
    } else {
      authStore.logout();
    }
    authStore.loaded = true;

    // https://swiperjs.com/element
    registerSwiperElement();

    // setup global directives and components
    app.use(shadow);
    app.use(PrimeVue, { unstyled: true });
    app.use(directives);
    // setup router
    app.use(router);
    // setup analytics
    const userId = authStore.idTokenParsed?.sub;
    const orgId = authStore.idTokenParsed?.org_id;
    app.use<AnalyticsPluginOptions>(analytics, {
      router,
      userId,
      orgId,
    });
    // wait for router to ensure layout is rendered correctly
    router.isReady().then(() => {
      app.mount("#app");
    });
  })
  .catch((error) => {
    console.error("Keycloak init fehlgeschlagen.", error);
    window.location.reload();
  });

async function createAppWithVueAxe() {
  const VueAxe = await import("vue-axe");
  const app = createApp({
    render: () => h(Fragment, [h(App), h(VueAxe.VueAxePopup)]),
  });
  app.use(VueAxe.default);
  return app;
}
