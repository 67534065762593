import { InjectionKey, inject } from "vue";

export interface Analytics {
  /// Log an event with the given name and data
  trackEvent<E extends EventName>(eventName: E, data: EventDataMap[E]): void;
  /// Enable automatic tracking of events like button clicks
  enableAutoTracking(): void;
}

export const injectionKey = Symbol() as InjectionKey<Analytics>;

//To define new events, extend this type and update any related mappings to until typescript is happy
export type EventName =
  | "tender_copied_to_clipboard"
  | "tender_downloaded"
  | "button_click"
  | "service_description_document_downloaded"
  | "document_research_assistant_text_selected";

/// Returns the matomo category of an event
export function getEventCategory(eventName: EventName): EventCategory {
  return EventCategoriesMap[eventName];
}

export type EventCategory = "service_descriptions" | "tenders" | "auto" | "ai";

const EventCategoriesMap: Record<EventName, EventCategory> = {
  tender_copied_to_clipboard: "tenders",
  tender_downloaded: "tenders",
  button_click: "auto",
  service_description_document_downloaded: "service_descriptions",
  document_research_assistant_text_selected: "ai",
};

type EventData = {
  [k: string]: string | number | null;
};

export type EventDataMap = Record<EventName, EventData> & {
  tender_copied_to_clipboard: {
    query: string;
    content: string;
    search_id: string | null;
    document_id: string | null;
  };
  tender_downloaded: { tender_id: string; tender_name: string };
  button_click: { title: string };
  service_description_downloaded: {
    service_description_id: string;
    document_type: string;
    format: string;
  };
  document_research_assistant_text_selected: { text: string; question: string };
};

export function useAnalytics(): Analytics | undefined {
  return inject(injectionKey);
}
