import { createAndSetupAxios } from "@/helpers/setupAxios";
import {
  User,
  UserCreate,
  Org,
  OrgWithId,
  UserName,
  UserPatchData,
  UserNameWithOrgId,
  LicenseData,
  OrgLicenseData,
  ContactRequest,
} from "@/types/user";
import { License, LicenseTypesNameMap, UserLicenses } from "@/types/licenses";
import type { UserManual } from "@/types/userManual";

const $axios = createAndSetupAxios(import.meta.env.VITE_IAM_ROUTE);

class IAMService {
  add_org(org: Org): Promise<OrgWithId> {
    return $axios.post(`orgs/`, org).then((response) => response.data);
  }

  add_user(user: UserCreate): Promise<string> {
    // TODO
    // from daniel m: window.location + /benutzer/login/ geht auch und funktioniert auf dev und prod
    user["redirect_uri"] = `https://app.govradar.net/`;
    return $axios.post(`users/`, user).then((response) => response.data);
  }

  get_orgs(): Promise<OrgWithId[]> {
    return $axios.get(`orgs/`).then((response) => response.data);
  }

  get_org_info(org_id: string): Promise<OrgWithId> {
    return $axios.get(`orgs/${org_id}/`).then((response) => response.data);
  }

  get_org_users(org_id: string): Promise<User[]> {
    return $axios
      .get(`orgs/${org_id}/users/`)
      .then((response) => response.data);
  }

  get_org_users_by_license_type(
    org_id: string,
    license: string,
  ): Promise<User[]> {
    return $axios
      .get(`orgs/${org_id}/users_by_license_type/${license}/`)
      .then((response) => response.data);
  }

  get_user_data(user_id: string): Promise<User> {
    return $axios.get(`users/${user_id}/`).then((response) => response.data);
  }

  get_user_licenses(user_id: string): Promise<LicenseData> {
    return $axios
      .get(`users/${user_id}/licenses/`)
      .then((response) => response.data);
  }

  get_licenses_by_org_id(org_id: string): Promise<OrgLicenseData> {
    return $axios
      .get(`/user_licenses/by_org_id/${org_id}/`)
      .then((response) => response.data);
  }

  //Returning type not defined in docs, did some manual calls to define type but should double check when type is defined in docs
  update_user(user_id: string, user_data: UserName): Promise<UserPatchData> {
    return $axios
      .patch(`users/${user_id}/`, user_data)
      .then((response) => response.data);
  }

  //Returning type not defined in docs, have to change unknown when type is defined in docs
  update_user_org_id(
    user_id: string,
    user_data: UserNameWithOrgId,
  ): Promise<unknown> {
    return $axios
      .patch(`users/${user_id}/update_org_id/`, user_data)
      .then((response) => response.data);
  }

  //Returning type not defined in docs, have to change unknown when type is defined in docs
  delete_user(user_id: string): Promise<unknown> {
    return $axios.delete(`users/${user_id}/`).then((response) => response.data);
  }

  update_org(org_id: string, org_data: Org): Promise<OrgWithId> {
    return $axios
      .patch(`orgs/${org_id}/`, org_data)
      .then((response) => response.data);
  }

  check_login_data(): Promise<User> {
    return $axios
      .get(`users/check_login_data/`)
      .then((response) => response.data);
  }

  async getAllLicenses(org_id: string): Promise<UserLicenses> {
    const response = await $axios.get(`user_licenses/by_org_id/${org_id}/`);
    return response.data;
  }

  async getLicenseTypes(): Promise<LicenseTypesNameMap> {
    const response = await $axios.get(`user_licenses/types/`);
    return response.data;
  }

  async assignUserToLicense(
    user_license_id: string,
    user_id: string,
  ): Promise<License> {
    const response = await $axios.patch(
      `user_licenses/${user_license_id}/assign/`,
      { user_license_id, user_id },
    );
    return response.data;
  }

  async resetUserOfLicense(user_license_id: string): Promise<License> {
    const response = await $axios.patch(
      `user_licenses/${user_license_id}/reset/`,
    );
    return response.data;
  }

  sendMessage(data: ContactRequest): Promise<void> {
    return $axios
      .post(`users/user_request_message/`, data)
      .then((response) => response.data);
  }

  sendPhoneRequest(data: ContactRequest): Promise<void> {
    return $axios
      .post(`users/user_request_phone/`, data)
      .then((response) => response.data);
  }

  async getUserManuals(): Promise<UserManual[]> {
    const response = await $axios.get(`user_manual/all/`);
    return response.data;
  }

  async getUserManual(id: string): Promise<UserManual> {
    const response = await $axios.get(`user_manual/${id}/`);
    return response.data;
  }

  getUserManualImageUrl(id: string): string {
    const baseUrl = $axios.defaults.baseURL;
    return `${baseUrl}user_manual/image/${id}/`;
  }

  async activate2FA(orgId: string): Promise<void> {
    await $axios.post(`orgs/${orgId}/activate_otp_2fa`);
  }
}

export default new IAMService();
