<template>
  <TieredMenu
    ref="menu"
    :model="items"
    :popup="popup"
    class="rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5"
    :pt="{
      submenu:
        'absolute rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 w-full flex-col',
      item: ({ context }: PassThroughOptions) => ({
        class: {
          'bg-indigo-100':
            (context.focused || context.active) && !context.disabled,
          relative: true,
        },
      }),
      itemContent: ({ context }: PassThroughOptions) => ({
        class: {
          'appearance-none select-none border border-transparent text-sm font-medium text-indigo-700 w-full': true,
          'cursor-default': context.disabled,
          'cursor-pointer': !context.disabled,
          'opacity-50': context.disabled && !context.item.heading,
          '!pl-5': anyItemIsHeading && !context.item.heading,
        },
        title: context.item.title,
      }),
      itemLabel: ({ context }: PassThroughOptions) => ({
        class: { 'font-bold text-black': context.item.heading },
      }),
      itemLink:
        'inline-flex items-center justify-start text-center w-full py-1.5 px-3 pl-2 gap-x-2.5',
      separator: 'my-0.5 h-px w-full bg-gray-200',
    }"
  >
    <template #submenuicon>
      <ChevronRightIcon class="ms-auto size-5" />
    </template>
    <template #itemicon="{ item }">
      <div
        v-if="anyItemHasIcon"
        class="size-5 text-indigo-700 opacity-70"
        :class="{
          'order-last ms-auto': item.iconPosition === 'end',
          '!text-black': item.heading,
        }"
      >
        <slot :name="`icon-${item.id}`">
          <Component :is="item.icon" v-if="item.icon" />
        </slot>
      </div>
    </template>
  </TieredMenu>
</template>

<script setup lang="ts">
import { ChevronRightIcon } from "@heroicons/vue/24/outline";
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import TieredMenu, {
  type TieredMenuPassThroughMethodOptions,
} from "primevue/tieredmenu";
import type { MenuItem } from "primevue/menuitem";

//eslint-disable-next-line @typescript-eslint/no-explicit-any
type PassThroughOptions = TieredMenuPassThroughMethodOptions<any>;

const props = defineProps<{
  items: MenuItem[];
  popup?: boolean;
}>();

const menu = ref();

const anyItemHasIcon = computed(() =>
  props.items.some((item) => "icon" in item),
);

const anyItemIsHeading = computed(() =>
  props.items.some((item) => "heading" in item),
);

defineExpose({ menu });

const emit = defineEmits<{
  close: [];
}>();

const handleKeydown = (e) => {
  //when pressing any button other than the ArrowButtons to navigate the button - close the menu (needed to close contextmenu in editor)
  if (
    !["ArrowRight", "ArrowLeft", "ArrowUp", "ArrowDown", "Enter"].includes(
      e.key,
    )
  ) {
    emit("close");
  }
};

onMounted(() => {
  addEventListener("keydown", handleKeydown);
});

onBeforeUnmount(() => {
  removeEventListener("keydown", handleKeydown);
});
</script>
