<script setup lang="ts">
import { EllipsisVerticalIcon } from "@heroicons/vue/24/outline";
import { ButtonNormal } from "@govradar/vue-components";
import type {
  ButtonKind,
  ButtonSize,
  Divider,
} from "@govradar/vue-components/types";
import type { Component } from "vue";
import { ref, computed } from "vue";
import type TieredMenu from "primevue/tieredmenu";
import type { MenuItem } from "primevue/menuitem";
import Menu from "@/components/Menu.vue";
import type { ButtonOrDivider } from "@/types/shared";
import NavBarButton from "@/components/NavBarButton.vue";

const props = withDefaults(
  defineProps<{
    kind?: ButtonKind | "navBar";
    size?: ButtonSize;
    fullDisabled?: boolean;
    menuItems: ButtonOrDivider[];
    icon?: Component;
    ariaLabel?: string;
  }>(),
  {
    kind: "action",
    size: "md",
    icon: EllipsisVerticalIcon,
  },
);

//PrimeVue uses command instead of action. Instead of changing all ButtonOther actions to command, transform object here, to stay conform with our Button types
const items = computed(() => props.menuItems.map(toPrimeVueItem));

function isDivider(item: ButtonOrDivider): item is Divider {
  return "divider" in item;
}

function toPrimeVueItem(item: ButtonOrDivider): MenuItem {
  if (isDivider(item)) return { separator: true };
  return {
    ...item,
    label: item.text,
    command: !item.disabled ? item.action : undefined,
    items: item.items?.map(toPrimeVueItem),
    key: item.id,
    icon: item.icon,
  };
}

const menu = ref<InstanceType<typeof TieredMenu> | null>(null);

function toggle(e: MouseEvent | KeyboardEvent) {
  menu.value?.menu.toggle(e);
}
</script>

<template>
  <div class="relative inline-block whitespace-nowrap">
    <NavBarButton
      v-if="kind === 'navBar'"
      :icon="icon"
      :icon-size="size"
      :aria-label="ariaLabel"
      @click="toggle"
      @keydown.enter="toggle"
    />
    <ButtonNormal
      v-else
      :kind="kind"
      :size="size"
      :class="{
        'px-1': size === 'sm',
        'px-2': size === 'md',
        'px-4': size === 'lg',
      }"
      :icon="icon"
      aria-haspopup="true"
      :disabled="fullDisabled"
      @click="toggle"
      @keydown.enter="toggle"
    >
      <span class="sr-only">Menü öffnen</span>
    </ButtonNormal>
    <Menu ref="menu" :items="items" popup />
  </div>
</template>
